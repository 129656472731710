import React, { createContext, useState } from 'react';

const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = props => {
	const { children } = props;
	const [state, setState] = useState({ active: [] });
	return <Provider value={[state, setState]}>{children}</Provider>;
};

export { AppContext, AppProvider };
