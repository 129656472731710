const theme = {
	borderColor: '#333',
	breakpointDesktop: '1080px',
	breakpointMobile: '600px',
	headerHeightDesktop: '64px',
	headerHeightMobile: '40px',
	linkActiveColor: 'rgba(255, 255, 0, 0.2)',
	paddingDesktop: '24px',
	paddingMobile: '12px',
};

export default theme;
