import React from 'react';
import styled from 'styled-components';

const Intro = () => {
	return <Container >
		<p>Personal websites are weird time capsules that occupy a strange space in the landscape of the Internet today (January 2022 as of this writing).</p>
		<p>
		Depending on when it was made, looking at your own website can cause crushing anxiety, misrepresenting your internal state or external accomplishments at the particular moment of access.
		</p>
		<p>It could be filled with triggers for setting off a sense of self that's unrealized or has changed — what you made before you knew what you know now, which aesthetics and references you thought hip, that you cared about coming across employable, intelligent, self-aware, radical, mysterious, aloof, etc. etc. etc.
		</p>
		<p>
		So this is mine for now. There's some design + technology documentation available upon request (jonger4 at gmail dot com). Some music / DJ stuff over <a href="https://soundcloud.com/offffffbrand">here</a>. And some writing over <a href="https://jffng.blog/">here</a> and some collections of things over <a href="https://are.na/jeff-ong">here</a>.
		</p>
		<em>Manifesto (Working)</em>
		<p>
			<em>Watching people do drugs is like watching people do VR.</em>
		</p>
		<p>
			<em>"Life is too meaningless to do things you don't want to do."</em>
		</p>
		<p>
			<em>I'll probably regret this.</em>
		</p>
		<p>
			<em>"If what you want is to make money, you can make nothing else."</em>
		</p>
		<p>
			<em>All I want is to vibe with all my friends at once.</em>
		</p>
		<p>
			<em>Better to be interested than interesting.</em>
		</p>
	</Container>;
}

const Container = styled.div`
	font-family: 'Times New Roman', serif;
	font-size: 17px;
	line-height: 1.3;
	max-width: 640px;
	padding: 0 1em;
	margin: 0;
`;

export default Intro;
