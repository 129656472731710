import React from 'react';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';

import { AppProvider } from '../hooks/appContext';
import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import theme from '../components/theme';
import Intro from '../components/Intro';

class Index extends React.Component {
	render() {
		return (
			<AppProvider>
				<ThemeProvider theme={theme}>
					<Layout>
						<Helmet title={config.siteTitle} />
						<SEO />
						<Intro/>
					</Layout>
				</ThemeProvider>
			</AppProvider>
		);
	}
}


export default Index;
